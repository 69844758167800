import { ASTROLOGER_PRODUCTS } from 'screens/advisors/screens/monetization/constants/interfaces';

export enum PRODUCT_TYPES {
  ONE_TIME = 'one_time',
  SUBSCRIPTION = 'subscription',
}

export enum REPORTS_PRODUCTS {
  NUMEROLOGY_ANALYSIS = 'numerology_analysis',
  PALMISTRY_GUIDE = 'palmistry_guide',
  ULTRA = 'numerology_analysis+palmistry_guide',
  ASTROLOGER_CONSULTATION = 'astrologer_consultation',
  BIRTH_CHART = 'birth_chart',
  COMPATIBILITY = 'compatibility',
  ULTRA_2 = 'birth_chart+compatibility',
}

export const PRODUCTS = {
  ...ASTROLOGER_PRODUCTS,
  ...REPORTS_PRODUCTS,
  // Define additional onetime products
} as const;

export type ONE_TIME_PRODUCTS = (typeof PRODUCTS)[keyof typeof PRODUCTS];
