import { Platform } from 'react-native';

import { PaymentMethods as WebPaymentMethod } from 'api/purchases/interfaces';

export enum OTHERS_PAYMENT_SCREENS {
  POPUP = 'popup', // reactivation modals
  SUB_MAIN = 'sub_main', // SUB_WEB
  CHAT = 'chat', // astrologer => chat => auto_refill
  TIPS = 'tips', // astrologer => chat => review => tips
}

export enum PAYMENT_METHODS {
  CARD = 'card',
  APPLE_PAY = 'apple-pay',
  GOOGLE_PAY = 'google-pay',
  PAYPAL = 'paypal',
}

export const PAYMENT_SYSTEM_PROJECT = Platform.select({
  ios: 'apple_pay_h2h',
  android: 'android',
  web: process.env.REACT_APP_PAYMENT_SYSTEM_PROJECT,
});

export const CURRENCY_SYMBOLS = {
  USD: '$',
  AUD: '$',
  CAD: '$',
  EUR: '€',
  GBP: '£',
  BRL: 'R$',
};

export enum PERIOD_TYPES {
  P7D = 'P7D',
  P14D = 'P14D',
  P30D = 'P30D',
  P60D = 'P60D',
  P2M = 'P2M',
  P5M = 'P5M',
  P360D = 'P360D',
  P90D = 'P90D',
  P1W = 'P1W',
  P3D = 'P3D',
  P2W = 'P2W',
  P180D = 'P180D',
  P1D = 'P1D',
  P1M = 'P1M',
  P3M = 'P3M',
  P6M = 'P6M',
  P7W = 'P7W',
  P1Y = 'P1Y',
}

export enum WEB_PAYMENT_SERVICES {
  SOLIDGATE = 'solidgate',
  RECURLY = 'recurly',
  STRIPE = 'stripe',
  PADDLE = 'paddle',
  PAYPAL = 'paypal',
}

export enum WEB_POST_MESSAGE_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  SKIPED = 'skiped',
  CLOSED = 'closed',
  BACK = 'back',
  LOADED = 'loaded',
}

export enum WEB_SUBSCRIPTION_STATE {
  ACTIVE = 'active',
  CANCELED = 'canceled', // recurly
  CANCELLED = 'cancelled', // solid
  SUSPENDED = 'SUSPENDED',
  REDEMPTION = 'redemption',
  DELETED = 'deleted',
  PROCESSED = 'processed',
  PAUSED = 'paused',
  PAST_DUE = 'past_due',
}

export enum WEB_SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  REDEMPTION = 'redemption',
}

export const ONECLICK_METHOD = '1click';
export const REACTIVATE_METHOD = 'reactivate';

export const AVAILABLE_ONECLICK_METHODS = [WebPaymentMethod.CARD, WebPaymentMethod.PAYPAL, WebPaymentMethod.APPLE_PAY, WebPaymentMethod.GOOGLE_PAY];
