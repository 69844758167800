import { createAction } from 'redux-actions';
import queryString from 'query-string';

import { AppDispatch, AppGetState, AppThunk } from 'store';
import { getWebSubscriptionToReactivate } from 'store/billing/selectors';
import { showModal } from 'store/modals/actions';
import { setOnboardingFlow } from 'store/remote-config/actions';
import { setAstrologistFreeTime } from 'store/astrologers/time/actions';
import Analytics from 'analytics';
import { PRO, SKU } from 'constants/billing';
import * as MODALS from 'constants/modals';
import * as TRIGGERS from 'constants/monetization-triggers';
import { navigateWithMonetization } from 'store/unlock-content/actions';

import { BILLING_TYPES } from './types';
import { trackEvent, initSubBenefits, cancelSubscription, fetchWebPurchases, verifyWebSubscriptions } from './general-actions';

export const setPending = createAction(BILLING_TYPES.SET_PENDING);
export const setWebActiveTransactions = createAction(BILLING_TYPES.SET_WEB_TRANSACTIONS);
export const setWebAllSubscriptions = createAction(BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS);
export const setTransactions = createAction(BILLING_TYPES.SET_TRANSACTIONS);
export const setPurchased = createAction(BILLING_TYPES.SET_PURCHASED);
export const setPurchaseDate = createAction(BILLING_TYPES.SET_PURCHASE_DATE);
export const setProducts = createAction(BILLING_TYPES.SET_PRODUCTS);
export const setProductsLoadSuccess = createAction(BILLING_TYPES.SET_PRODUCTS_LOAD_SUCCESS);
export const setWebSubscriptionShowed = createAction(BILLING_TYPES.SET_WEB_SUBSCRIPTION_SHOWED);

export const initBilling = (): AppThunk<any> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setPending(true));
    const startTime = new Date().getTime();
    trackEvent('init_start', { startTime });

    try {
      await dispatch(fetchWebPurchases());
      const eventData = { executionTime: Date.now() - startTime };
      trackEvent('init_finish', eventData);

      dispatch(verifySubscriptions());

      const {
        remoteConfig: { error: isFetchRemoteConfigError },
        billing: { purchased },
      } = getState();

      if (isFetchRemoteConfigError && purchased) {
        dispatch(setOnboardingFlow([]));
      }

      dispatch(setPending(false));
    } catch (error) {
      trackEvent('init_error', { error });
      console.log(`[Billing] Init failed ${error}`);
      dispatch(setPending(false));
      return [];
    }
  };
};

export const addFakePurchase = () => {
  return (dispatch: AppDispatch) => {
    dispatch(setPurchased({ purchased: true, purchasedAstrologers: true }));
    dispatch(setAstrologistFreeTime());
  };
};

export const verifySubscriptions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      onboarding: { isOnboardingCompleted },
    } = getState();

    const subscriptions = await dispatch(verifyWebSubscriptions());

    if (subscriptions.length) {
      dispatch(initSubBenefits());
    }

    const showReactivationSub = dispatch(reactivationHandler(TRIGGERS.START_APP));

    if (isOnboardingCompleted && showReactivationSub) {
      setTimeout(() => {
        dispatch(navigateWithMonetization({ trigger: TRIGGERS.REACTIVATION_ON_START }));
      }, 2000);
    }

    return subscriptions;
  };
};

export const verifyMobilePurchases = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const reactivationHandler = (trigger: string): AppThunk<boolean> => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      remoteConfig: {
        remoteConfigParams: { enableReactivationModal },
      },
    } = state;

    const isSubsToReactivateAvailable = getWebSubscriptionToReactivate(state);

    if (enableReactivationModal && isSubsToReactivateAvailable) {
      dispatch(showModal(MODALS.REACTIVATION, { trigger }));
      return false;
    }

    // show sub
    return true;
  };
};

export const setupPurchasedState = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { transactions, webTransactions },
    } = getState();

    let purchased = !!webTransactions?.length || transactions?.some(item => item?.productId?.indexOf(SKU) === 0);

    const purchasedAstrologers = transactions?.some(item => item?.productId?.includes?.(PRO));
    const p = queryString.parse(window.location.search);
    purchased = purchased || p.purchased !== undefined;

    dispatch(setPurchased({ purchased, purchasedAstrologers }));

    dispatch(setAstrologistFreeTime());

    const userType = purchased || purchasedAstrologers ? 'subscriber' : 'free';
    Analytics.setUserProperty('user_type', userType);
  };
};

export const subscribe = (_productId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));
    try {
      dispatch(setPending(false));
    } catch (error: any) {
      // Empty
    }
  };
};

export const purchase = (_productId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));
    try {
      dispatch(setPending(false));
    } catch (error: any) {
      // Empty
    }
  };
};

export const purchaseWithOffer = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const purchaseAstrologer = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const restore = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const getProductDetails = (productId: string): any => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { products },
    } = getState();

    return products.find(item => item.productId === productId);
  };
};

export const cancelSubscriptions = () => {
  return (dispatch: AppDispatch) => {
    return dispatch(cancelSubscription());
  };
};
