import React, { FC, useEffect, useMemo } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import _ from 'lodash';

import Analytics from 'analytics';
import { PAYMENT_EVENTS_CATEGORIES } from 'analytics/const';
import { useAppSelector, useAppDispatch } from 'store';
import { verifyWebSubscriptions } from 'store/billing/general-actions';
import { getWebSubscriptionToReactivate } from 'store/billing/selectors';
import { showModal, closeCurrentModal } from 'store/modals/actions';
import { t } from 'localization';
import Text from 'components/text';
import HighLightText from 'components/hight-light-text';
import ReactivateSubscriptionButton from 'components/web-payment-iframe-buttons/reactivate-subscription';
import RenewSubscriptionButton from 'components/web-payment-iframe-buttons/renew-subscription';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { WEB_SUBSCRIPTION_STATUS, OTHERS_PAYMENT_SCREENS } from 'modules/payments/constants';

import IMAGE_2 from './images/2-2.png';

interface Props {}

const WebReactivationModal: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(state => state.modals.activeModal === MODALS.REACTIVATION);
  const subsToReactivate = useAppSelector(getWebSubscriptionToReactivate);
  const notRenewButtonConfig = useAppSelector(state => state.remoteConfig.remoteConfigParams?.reactivateCloseButton);
  const trigger = useAppSelector(state => state.modals.params?.trigger);

  const status = useMemo(() => {
    if (subsToReactivate) {
      return subsToReactivate?.status;
    }

    return 0;
  }, [subsToReactivate]);

  const eventParams = useMemo(() => {
    return { trigger, screen: OTHERS_PAYMENT_SCREENS.POPUP };
  }, [trigger]);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Open', eventParams);
    }
  }, [isVisible]);

  const handleClosePress = () => {
    dispatch(closeCurrentModal());
  };

  const handleNotRenewPress = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'NotRenew_Click', eventParams);

    if (!notRenewButtonConfig?.link) {
      handleClosePress();
    }
  };

  const handlePress = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Submit', {
      ...eventParams,
      status,
    });
  };

  const handleSkip = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Skip', {
      ...eventParams,
      status,
    });

    dispatch(closeCurrentModal());
  };

  const handleSuccess = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Success', {
      ...eventParams,
      status,
    });

    dispatch(verifyWebSubscriptions());
    dispatch(closeCurrentModal());
    dispatch(showModal(MODALS.REACTIVATION_THX));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <Text font="Philosopher" style={styles.title}>
          {_.startCase(t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.TITLE'))}
        </Text>

        <HighLightText style={styles.subTitle} highlightedTextStyle={styles.subTitleHightLight}>
          {status === WEB_SUBSCRIPTION_STATUS.EXPIRED
            ? t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.SUB_TITLE_EXPIRED')
            : t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.SUB_TITLE_CANCELED')}
        </HighLightText>

        <Image style={styles.image} source={IMAGE_2} resizeMode="contain" />

        <ReactivateSubscriptionButton
          style={styles.button}
          buttonTitle={t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.BTN_TITLE')}
          onPress={handlePress}
          onSkip={handleSkip}
          onSuccess={handleSuccess}
          isReactivate={true}
        />

        <RenewSubscriptionButton
          buttonTitle={!notRenewButtonConfig?.title ? t('SUBSCRIPTION.REACTIVATION.CANCEL_EXPIRED.DON_T_RENEW') : notRenewButtonConfig?.title}
          link={notRenewButtonConfig.link}
          onPress={handleNotRenewPress}
          onSkip={handleSkip}
          onSuccess={handleSuccess}
        />
      </View>
    </Modal>
  );
};

export default WebReactivationModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
    paddingTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(32),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
  },
  image: {
    width: sw(285),
    marginTop: paddingVertical(30),
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: paddingVertical(22),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(34),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(15),
    fontSize: fs(18),
    lineHeight: fs(25),
    color: COLORS.WHITE_WITH_FIFTH_TRANSPARENCY,
    textAlign: 'center',
  },
  subTitleHightLight: {
    color: COLORS.FADED_ORANGE,
  },
  button: {
    marginTop: paddingVertical(24),
  },
});
