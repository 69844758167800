export const SHOW_EVENTS_LOGS = false;

export enum PAYMENT_EVENTS_CATEGORIES {
  ASTROLOGIST_PAYMENT = 'Astrologist_Payment', //astrologist pack
  SUBSCRIPTION = 'Subscription', //web or mobile subscriptions
  REACTIVATION = 'Reactivation', //reactivate web subscriptions
}

export enum OTHER_PLANS_TRIGGERS {
  PACKS = 'other_plans_packs',
  REACTIVATION = 'other_plans_reactivation',
  SUB_BENEFITS = 'other_plans_sub_benefits_popup',
}
