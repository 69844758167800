import { WEB_PAYMENT_SERVICES, WEB_SUBSCRIPTION_STATUS, WEB_SUBSCRIPTION_STATE, PERIOD_TYPES } from 'modules/payments/constants';
import { Currency } from 'modules/payments/interfaces';
import { ONE_TIME_PRODUCTS } from 'modules/payments/constants/product';
import { ApplePayDetailsPaymentData } from 'modules/payments/apple-pay/interfaces';

interface WebProductCurrency {
  amount: string;
  currency: Currency;
  trial_price_amount: string;
  country: string;
}

export interface WebProduct {
  resource_type: string;
  id: string;
  amount: string;
  currency: Currency;
  trial_period_days: string;
  trial_price_amount: string;
  pay_link: null;
  period: PERIOD_TYPES;
  currencies: WebProductCurrency[];
}

export interface WebPurchase {
  resource_type: string;
  id: number;
  external_id: string;
  payment_service: WEB_PAYMENT_SERVICES;
  active: number;
  currency: Currency;
  product: string;
  trial_start_at: string | null;
  trial_end_at: string | null;
  expire_at: string;
  canceled_at: string | null;
  created_at: string;
  updated_at: string;
  customer_email: string;
  amount: number;
  email: string;
  sig: string;
  state: WEB_SUBSCRIPTION_STATE;
}

export interface WebSubscription extends Omit<WebPurchase & WebProduct, 'amount'> {
  status: WEB_SUBSCRIPTION_STATUS;
  amount: number;
  trial: number;
  trial_period: PERIOD_TYPES | null;
  is_active_trial_period: boolean;
}

export interface WebOneTimePurchase {
  id: number;
  payment_service: WEB_PAYMENT_SERVICES;
  project: string;
  product_code: ONE_TIME_PRODUCTS;
  order_id: string;
  email: string;
  amount: number;
  currency: Currency;
  user_id: number;
  customer_id: string;
  subscription_id: number;
  description: string;
  status: number;
  created_at: string;
  updated_at: string;
  trigger: string | null;
  metadata: string | null;
}

export interface SolidMetadata {
  adjust_id?: string;
  idfm?: string;
  idfv?: string;
  idfa?: string;
  firebase_instance_id?: string;
  web_uuid?: string;
}

export interface PaymentData {
  amount: number;
  currency: Currency;
  productCode: string;
  metadata: any;
  description: string;
  trigger?: string;
  solidMetadata?: SolidMetadata;
}

export interface OneClickPaymentData extends PaymentData {}

export interface SubscriptionPaymentData extends Omit<PaymentData, 'productCode' | 'amount' | 'currency'> {
  productId: string;
}

export interface ApplePayPaymentData extends PaymentData {
  data: string;
  header: ApplePayDetailsPaymentData['header'];
  signature: ApplePayDetailsPaymentData['signature'];
  version: string;
  email: string;
}

export interface GooglePayPaymentData extends PaymentData {
  signature: string;
  signedMessage: string;
  protocolVersion: string;
  merchant: string;
  platform: 'APP';
  amount: number;
  currency: Currency;
  email: string;
}

export enum PaymentMethods {
  CARD = 'card',
  PAYPAL = 'paypal',
  APPLE_PAY = 'applepay',
  GOOGLE_PAY = 'googlepay',
}

export interface OnetimePaymentResponse {
  verify_url?: string;
  one_time_payment: {
    payment_service: WEB_PAYMENT_SERVICES;
    project: string;
    order_id: string;
    product_code: string;
    email: string;
    user_id: string;
    customer_id: string;
    currency: string;
    amount: string;
    description: string;
    status: string;
    trigger: string;
    metadata: string;
    created_at: string;
    updated_at: string;
    id: string;
    session_number: string;
    retention_day: string;
  };
}

export interface SubscriptionPaymentResponse {
  data: {
    resource_type: string;
    transaction_id: string;
    plan_name: string;
    email: string;
    currency: Currency;
    amount: number;
    customer_id: string;
    created_at: string;
    trial_start_at: null | string;
    trial_end_at: null | string;
    expire_at: string;
    canceled_at: null | string;
    state: WEB_SUBSCRIPTION_STATE;
    is_active: true;
    provider: WEB_PAYMENT_SERVICES;
    tracks: any[];
  };
}

export type PaymentResponse = OnetimePaymentResponse | SubscriptionPaymentResponse;
