import { Transaction } from '@wowmaking/react-native-billing';

import { WEB_PAYMENT_SERVICES, PAYMENT_SYSTEM_PROJECT } from 'modules/payments/constants';

import api from '..';

import {
  WebPurchase,
  WebOneTimePurchase,
  WebProduct,
  OneClickPaymentData,
  ApplePayPaymentData,
  PaymentMethods,
  GooglePayPaymentData,
  SubscriptionPaymentData,
  OnetimePaymentResponse,
  SubscriptionPaymentResponse,
} from './interfaces';

const PAYMENTS = '/payments';
const PURCHASE = '/purchase';
const REGISTER = '/register';
const SUBS = '/subscriptions';
const ONE_TIME = '/one-time-payment';

const DEFAULT_PROJECT = 'quiz';

export const createApplePayOrder = ({
  amount,
  trigger,
  currency,
  productCode,
  description,
  metadata,
  data,
  header,
  signature,
  version,
  email,
  solidMetadata,
}: ApplePayPaymentData): Promise<OnetimePaymentResponse> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}/create-by-apple-pay`, {
    method: 'POST',
    data: {
      payment_system_type: WEB_PAYMENT_SERVICES.SOLIDGATE,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      trigger,
      amount,
      currency,
      product_code: productCode,
      description,
      metadata: metadata ? JSON.stringify(metadata) : '',
      data,
      header,
      signature,
      version,
      customer_email: email,
      solid_metadata: solidMetadata,
    },
  });
};

export const createGooglePayOrder = ({
  amount,
  trigger,
  currency,
  merchant,
  platform,
  signature,
  description,
  productCode,
  metadata,
  email,
  solidMetadata,
  signedMessage,
  protocolVersion,
}: GooglePayPaymentData): Promise<OnetimePaymentResponse> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}/create-by-google-pay`, {
    method: 'POST',
    data: {
      payment_system_type: WEB_PAYMENT_SERVICES.SOLIDGATE,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      trigger,
      amount,
      currency,
      merchant,
      platform,
      signature,
      description,
      product_code: productCode,
      metadata: metadata ? JSON.stringify(metadata) : '',
      customer_email: email,
      solid_metadata: solidMetadata,
      signed_message: signedMessage,
      protocol_version: protocolVersion,
    },
  });
};

export const createOneClickPayment = ({
  amount,
  trigger,
  currency,
  productCode,
  description,
  metadata,
  solidMetadata,
}: OneClickPaymentData): Promise<OnetimePaymentResponse> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}`, {
    method: 'POST',
    data: {
      payment_system_type: WEB_PAYMENT_SERVICES.SOLIDGATE,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      trigger,
      amount,
      currency,
      product_code: productCode,
      description,
      metadata: metadata ? JSON.stringify(metadata) : '',
      solid_metadata: solidMetadata,
    },
  });
};

export const createSubscriptionByOneClick = ({
  trigger,
  productId,
  description,
  metadata,
  solidMetadata,
}: SubscriptionPaymentData): Promise<SubscriptionPaymentResponse> => {
  return api.fetch(`${PAYMENTS}${SUBS}/create-new-subscription-by-card-token`, {
    method: 'POST',
    data: {
      payment_system_type: WEB_PAYMENT_SERVICES.SOLIDGATE,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      trigger,
      product_id: productId,
      fail_url: '-',
      success_url: '-',
      description,
      metadata: metadata ? JSON.stringify(metadata) : '',
      solid_metadata: solidMetadata,
    },
  });
};

export const checkOneTimePaymentStatus = (orderId: string, project = DEFAULT_PROJECT, isPaypal = false): Promise<any> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}/callback/${project}/${WEB_PAYMENT_SERVICES.SOLIDGATE}`, {
    method: 'POST',
    data: {
      is_paypal: isPaypal,
      order_id: orderId,
    },
  });
};

export const getWebSubs = (): Promise<WebPurchase[]> => {
  return api
    .fetch(`${PAYMENTS}${SUBS}?search=active:1`, {
      method: 'GET',
    })
    .then(r => r.data)
    .catch(() => []);
};

export const getWebAllSubs = (): Promise<WebPurchase[]> => {
  return api
    .fetch(`${PAYMENTS}${SUBS}/actualized`, {
      method: 'GET',
    })
    .then(r => {
      return r.data;
    });
};

export const getProducts = (ids: string[] = []): Promise<WebProduct[]> => {
  let query = `?payment_system_type=${WEB_PAYMENT_SERVICES.SOLIDGATE}&payment_system_project=${PAYMENT_SYSTEM_PROJECT}`;

  if (ids.length) {
    query += `&search=external_id:${ids.join(',')}&searchFields=external_id:in`;
  }

  return api
    .fetch(`${PAYMENTS}/products${query}`, {
      method: 'GET',
    })
    .then(r => {
      return r.data;
    });
};

export const getWebOneTimePurchases = (): Promise<WebOneTimePurchase[]> => {
  return api
    .fetch(`${PAYMENTS}${ONE_TIME}`, {
      method: 'GET',
    })
    .then(r => r)
    .catch(() => []);
};

export const getPaymentMethod = (): Promise<PaymentMethods | ''> => {
  return api
    .fetch(`${PAYMENTS}${ONE_TIME}/get-payment-method`, {
      method: 'GET',
    })
    .then((r: { payment_method: PaymentMethods }) => {
      return r.payment_method;
    })
    .catch(err => {
      console.log('[ERROR GET PAYMENT METHOD]:', err);
      return '';
    });
};

export const registerSub = (data: Transaction[]): Promise<Transaction> => {
  return api.fetch(`${PURCHASE}${REGISTER}`, {
    method: 'POST',
    data: {
      data,
    },
  });
};
