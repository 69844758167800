import React, { FC, useCallback } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import { getAvailablePDFReports } from 'store/upsails/selectors';
import { t, t2 } from 'localization';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { PDF_REPORTS } from 'constants/upsale';
import { openReport } from 'store/upsails/actions';
import { PERSONAL_REPORTS } from 'components/personal-reports-banner/const';
import { PersonalReport } from 'components/personal-reports-banner/interfaces';

import ICON_CROSS from '../images/beige-cross.png';

import IMAGE from './images/image.png';

const PersonalReportsModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.PERSONAL_REPORTS);
  const reports = useSelector(getAvailablePDFReports);

  const handleLinkPress = useCallback((report: PDF_REPORTS) => {
    dispatch(closeCurrentModal());
    dispatch(openReport(report, 'notification'));
  }, []);

  if (!reports.length) {
    return null;
  }

  const handleClosePress = () => {
    dispatch(closeCurrentModal());
  };

  const renderLink = (report: PDF_REPORTS) => {
    const product: PersonalReport = PERSONAL_REPORTS[report];

    if (!product) {
      return null;
    }

    const title = t2(`PERSONAL_REPORTS.${product.title}.TITLE`);

    const handlePress = () => {
      handleLinkPress(report);
    };

    return (
      <TouchableOpacity key={report} onPress={handlePress} style={styles.link}>
        <Text style={styles.linkText}>{title}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <Image style={styles.image} source={IMAGE} resizeMode="contain" />
        <Text font="Philosopher" style={styles.title}>
          {t('MODALS.PERSONAL_REPORTS.TITLE')}
        </Text>
        {reports.map(renderLink)}
        <TouchableOpacity style={styles.cross} onPress={handleClosePress}>
          <Image source={ICON_CROSS} />
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(40),
    paddingTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(32),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
  },
  image: {
    width: sw(140),
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: paddingVertical(30),
    marginBottom: paddingVertical(15),
    fontSize: fs(24),
    fontWeight: '700',
    lineHeight: 28,
    textAlign: 'center',
  },
  link: {
    marginTop: paddingVertical(30),
  },
  linkText: {
    fontSize: fs(15),
    lineHeight: fs(15),
    color: COLORS.AQUA,
    fontWeight: '700',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  cross: {
    position: 'absolute',
    top: paddingVertical(20),
    right: paddingHorizontal(20),
  },
});

export default PersonalReportsModal;
