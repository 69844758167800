import { WEB_LINK_DEV_DEFINER } from 'constants/general';
import { PDF_REPORTS } from 'constants/upsale';

import BIRTH_CHART from './img/birth-chart.png';
import COMPATIBILITY from './img/compatibility.png';
import { PersonalReports } from './interfaces';

export const PERSONAL_REPORTS: PersonalReports = {
  [PDF_REPORTS.BIRTH_CHART]: {
    title: 'BIRTH_CHART',
    image: BIRTH_CHART,
    pathName: `birth-chart${WEB_LINK_DEV_DEFINER}`,
  },
  [PDF_REPORTS.COMPATIBILITY]: {
    title: 'COMPATIBILITY',
    image: COMPATIBILITY,
    pathName: 'compatibility',
  },
};
