import * as ROUTES from './routes';

export enum SUB_SCREENS {
  TIMER = 'timer',
  SPECIAL_OFFER = 'special_offer',
  PROMO_OFFER = 'promo_offer',
  VERTICAL = 'vertical_products',
  FAMILY = 'family',
  PALM = 'palm',
  POST = 'post',
  VERTICAL_ONE = 'vertical_one',
  WEB = 'web',
}

export const MAP: { [key in SUB_SCREENS]: string } = {
  [SUB_SCREENS.TIMER]: ROUTES.SUBSCRIPTION_TIMER,
  [SUB_SCREENS.SPECIAL_OFFER]: ROUTES.SUBSCRIPTION_SPECIAL_OFFER,
  [SUB_SCREENS.PROMO_OFFER]: ROUTES.SUBSCRIPTION_PROMO_OFFER,
  [SUB_SCREENS.VERTICAL]: ROUTES.SUBSCRIPTION_VERTICAL_PRODUCTS,
  [SUB_SCREENS.FAMILY]: ROUTES.SUBSCRIPTION_FAMILY,
  [SUB_SCREENS.PALM]: ROUTES.SUBSCRIPTION_PALM,
  [SUB_SCREENS.POST]: ROUTES.SUBSCRIPTION_POST,
  [SUB_SCREENS.VERTICAL_ONE]: ROUTES.SUBSCRIPTION_VERTICAL_ONE_PRODUCT,
  [SUB_SCREENS.WEB]: ROUTES.SUBSCRIPTION_WEB,
};
