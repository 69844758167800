import format from 'string-format';

import { t2 } from 'localization';
import { WebSubscription, WebPurchase, WebProduct } from 'api/purchases/interfaces';

import { Currency } from '../interfaces';
import { Product } from '../interfaces/product';
import { WEB_SUBSCRIPTION_STATE, WEB_SUBSCRIPTION_STATUS, CURRENCY_SYMBOLS, PERIOD_TYPES } from '../constants';
import { PRODUCT_TYPES } from '../constants/product';

export const getPriceText = (text: string, product: Product) => {
  const p = { ...product };
  if (p.type === PRODUCT_TYPES.SUBSCRIPTION && p?.period) {
    p.period = t2(`SUBSCRIPTION.PERIODS.${p.period}`);
  }

  return format(text, p);
};

export const getPreparedPeriod = (period: PERIOD_TYPES): PERIOD_TYPES => {
  switch (period) {
    case PERIOD_TYPES.P7D:
      return PERIOD_TYPES.P1W;
    case PERIOD_TYPES.P14D:
      return PERIOD_TYPES.P2W;
    case PERIOD_TYPES.P30D:
      return PERIOD_TYPES.P1M;
    case PERIOD_TYPES.P60D:
      return PERIOD_TYPES.P2M;
    case PERIOD_TYPES.P90D:
      return PERIOD_TYPES.P3M;
    case PERIOD_TYPES.P180D:
      return PERIOD_TYPES.P6M;
    default:
      return period as PERIOD_TYPES;
  }
};

export const getCurrencySymbol = (currency: Currency): string => {
  return CURRENCY_SYMBOLS[currency as keyof typeof CURRENCY_SYMBOLS] ?? currency;
};

export const mapWebProduct = (product: WebProduct): WebProduct => {
  const { period } = product;
  return {
    ...product,
    period: getPreparedPeriod(period),
  } as WebProduct;
};

export const mapWebSubscription = (purchase: WebPurchase, products: WebProduct[]): WebSubscription => {
  const { trial_start_at, trial_end_at } = purchase;
  const purchaseState = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATE;
  let status = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATUS;
  const product = products.find(p => p.id === purchase.product) || {};

  let isActiveTrialPeriod = false;

  if (trial_start_at && trial_end_at) {
    const endDate = new Date(trial_end_at);
    const now = new Date();

    isActiveTrialPeriod = endDate >= now;
  }

  if (
    [WEB_SUBSCRIPTION_STATE.CANCELED, WEB_SUBSCRIPTION_STATE.CANCELLED].includes(purchaseState) ||
    (purchaseState === WEB_SUBSCRIPTION_STATE.ACTIVE && purchase.canceled_at)
  ) {
    status = WEB_SUBSCRIPTION_STATUS.CANCELED;
  }

  if (purchase.expire_at) {
    const now = new Date();
    const expireDate = new Date(purchase.expire_at.replace(' ', 'T'));

    if (now > expireDate) {
      status = WEB_SUBSCRIPTION_STATUS.EXPIRED;
    }
  }

  if (purchaseState === WEB_SUBSCRIPTION_STATE.REDEMPTION) {
    status = WEB_SUBSCRIPTION_STATUS.REDEMPTION;
  }

  return {
    ...product,
    ...purchase,
    status,
    is_active_trial_period: isActiveTrialPeriod,
  } as WebSubscription;
};
